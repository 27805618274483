.gallery-wrapper {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.gallery-content {
  padding-left: 0px;
  list-style: none;
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  justify-content: center;
  align-items: center;
  gap: 50px;
  max-width: 1500px;
  margin: 0px 40px;
}

.gallery-content-cont {
  flex-basis: 31%;
}

@media only screen and (max-width: 640px) {
  .gallery-content {
    flex-direction: column;
  }
}

/*

.gallery-content {
  padding-left: 0px;
  list-style: none;
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  justify-content: center;
  align-items: center;
  margin: 0px 60px;
  gap: 40px;
}

.gallery-content li {
  flex-basis: 30%;
  margin-bottom: 100px;
  max-width: 400px;
}

.gallery-content li img {
  object-fit: cover;
  max-width: 100%;
  height: auto;
  vertical-align: middle;
}

@media only screen and (max-width: 640px) {
  .gallery-content {
    flex-direction: column;
  }
}

*/
