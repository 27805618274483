@import url('https://fonts.googleapis.com/css2?family=Playfair+Display:ital,wght@0,400;0,500;0,600;0,700;0,800;0,900;1,400;1,500;1,600;1,700;1,800;1,900&display=swap');
:root {
  --light-sage: #e6f0e7;
  --medium-sage: #c0d4c3;
  --dark-sage: #abc5ae
  --site-font-1: Aboreto;
  --toastify-font-family: Playfair Display!important;
  --toastify-color-progress-success: white!important;
  --toastify-color-progress-error: white!important;
  --toastify-color-success: white!important;
  --toastify-color-warning: white!important;
}

body {
  overflow-x: hidden;
  margin: 0;
  padding: 0;
  color: black;
  font-family: Playfair Display;
  letter-spacing: 1px;
  font-weight: 300;
}

@keyframes on-scroll-load {
  from {
    opacity: 0;
    transform:translateX(10%);
  }

  to {
    opacity: 1;
    transform:translateX(0%);
  }
}

@-moz-keyframes on-scroll-load {
  from {
    opacity: 0;
    transform:translateX(10%);
  }

  to {
    opacity: 1;
    transform:translateX(0%);
  }
}

/* Safari, Chrome and Opera > 12.1 */
@-webkit-keyframes  on-scroll-load {
  from {
    opacity: 0;
    transform:translateX(10%);
  }

  to {
    opacity: 1;
    transform:translateX(0%);
  }
}

/* Internet Explorer */
@-ms-keyframes  on-scroll-load {
  from {
    opacity: 0;
    transform:translateX(10%);
  }

  to {
    opacity: 1;
    transform:translateX(0%);
  }
}

/* Opera < 12.1 */
@-o-keyframes  on-scroll-load {
  from {
    opacity: 0;
    transform:translateX(10%);
  }

  to {
    opacity: 1;
    transform:translateX(0%);
  }
}



@keyframes fadein {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}

/* Firefox < 16 */
@-moz-keyframes fadein {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}

/* Safari, Chrome and Opera > 12.1 */
@-webkit-keyframes fadein {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}

/* Internet Explorer */
@-ms-keyframes fadein {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}

/* Opera < 12.1 */
@-o-keyframes fadein {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}