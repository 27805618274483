.about-wrapper {
  min-height: 100vh;
  margin: 0px 40px;
}

.about-info-container {
  width: 100%;
  margin-top: 200px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 100px;
}

.about-item-1 {
  width: 100%;
  display: flex;
  justify-content: space-evenly;
  align-items: center;
}
.about-item-2 {
  width: 100%;
  display: flex;
  flex-direction: row-reverse;
  justify-content: space-evenly;
  align-items: center;
}

.about-title {
  font-size: 30px;
}

.about-text-box {
  background-color: var(--light-sage);
  padding: 30px 30px;
  max-width: 450px;
}

.about-name-highlight {
  margin: 0;
}

.about-text {
  font-size: 13px;
  margin-top: 1px;
  margin-bottom: 0;
  line-height: 20px;
}

@media only screen and (max-width: 800px) {
  .about-item-1,
  .about-item-2 {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    margin: 0px 10px;
  }
}
