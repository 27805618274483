.error {
  color: red;
  font-size: 12px;
  margin-top: 5px;
  margin-left: 5px;
  text-align: left;
}

.myTextInput-wrapper {
  margin-top: 30px;
  margin-bottom: 50px;
  text-align: left;
}

.myTextInput-wrapper label {
  text-align: left;
}

.text-input {
  border: none;
  border-bottom: 1px solid black;
  width: 100%;
  font-size: 15px;
  margin-top: 12px;
  padding-bottom: 4px;
}

.text-input-error {
  border-bottom: 1px solid red;
}

.text-input:focus {
  outline: none;
}

.textarea-input {
  font-family: aboreto;
  box-sizing: border-box;
}

.textarea-input:focus {
  outline: none;
}

.textarea-input-error {
  border: 1px solid red !important;
}

.myPhoneInput-wrapper {
  margin: 0px;
  margin-top: 30px;
  margin-bottom: 50px;
}

.myPhoneInput-wrapper fieldset {
  border: none;
  display: flex;
  flex-direction: row;
  gap: 10px;
  padding: 0px;
  margin: 0px;
}

.myPhoneInput-wrapper legend {
  border: none;
  display: flex;
  flex-direction: row;
  gap: 10px;
  padding: 0px;
  margin: 0px;
}

.phoneSetInput-wrapper label {
  margin: 10px 0px;
  padding: 0px;
  text-align: center;
}

.phone-text-input {
  border: 1px solid rgb(0, 0, 0, 0.5);
  border-radius: 5px;
  width: 40px;
  height: 22.5px;
  max-width: 50px;
  font-size: 18px;
  font-family: aboreto;
  padding: 5px;
  text-align: center !important;
}

.phone-text-input:focus {
  outline: none;
}

.phone-text-input-error {
  border-color: red;
}

.myEventDate-wrapper {
  margin: 0px;
  margin-top: 30px;
  margin-bottom: 50px;
  text-align: left;
}

.myEventDate-wrapper input {
  margin: 0px;
  margin-top: 5px;
  font-family: Aboreto;
  font-size: 15px;
  padding: 10px;
}

.mySelect-wrapper {
  text-align: left;
  margin: 0px;
  margin-top: 30px;
  margin-bottom: 50px;
}

.mySelect-wrapper select {
  margin: 0px;
  margin-top: 5px;
  font-family: Aboreto;
  font-size: 15px;
  border-radius: 5px;
  padding: 10px;
  border-style: none;
}

.select-error {
  border: 1px solid red !important;
}
