.nav-wrapper {
  height: 140px;
  width: 100vw;
  background-color: transparent;
  display: flex;
  justify-content: space-between;
  align-content: center;
  align-items: center;
  position: fixed;
  top: 0;
  transition: height 0.5s ease-out, background-color 0.5s ease-out;
  z-index: 10;
  opacity: 0;
  -webkit-animation: fadein 1s 0.25s forwards; /* Safari, Chrome and Opera > 12.1 */
  -moz-animation: fadein 1s 0.25s forwards; /* Firefox < 16 */
  -ms-animation: fadein 1s 0.25s forwards; /* Internet Explorer */
  -o-animation: fadein 1s 0.25s forwards; /* Opera < 12.1 */
  animation: fadein 1s 0.25s forwards;
}

.covered-nav {
  background-color: white;
}

.logo a {
  text-decoration: none;
  color: black;
  font-weight: 500;
  white-space: nowrap;
  font-size: 50px;
  transition: all ease-in-out 250ms;
}

.logo a:hover {
  color: white;
}

.covered-nav .logo a:hover {
  color: black;
  opacity: 0.3;
}

.logo {
  margin: 0 51.5px;
}

.nav-links {
  display: flex;
  margin: 1vh 51.5px;
  margin-left: 0;
  padding-left: 0;
  list-style: none;
  width: auto; /*! seems to be causing a conflict with how the nav links fit into the nav-wrapper */
  align-items: center;
  text-transform: uppercase;
}

.nav-links li a {
  cursor: pointer;
  text-decoration: none;
  letter-spacing: 1.75px;
  color: black;
  font-size: 16px;
  transition: all ease-in-out 250ms;
}

.nav-links li a {
  background: transparent;
  border: none;
  margin: 0;
  padding: 0;
  text-transform: uppercase;
  font-family: Aboreto;
  font-weight: 300;
}

.nav-links li a:hover {
  opacity: 0.5;
}

.covered-nav-link {
  color: #8a9a5b;
}

.nav-link-item {
  margin-right: 1rem;
  position: relative;
}

.nav-links li a::before {
  content: "";
  display: block;
  height: 3px;
  width: 0%;
  background-color: #8a9a5b;
  position: absolute;
  top: 21px;
  transition: all ease-in-out 250ms;
  margin: 0 0 0 10%;
}

.nav-links li a:hover::before {
  width: 80%;
}

.nav-link-item:last-child {
  margin-right: 0;
}

.nav-hamburger div {
  width: 23px;
  height: 1px;
  background: black;
  margin: 6.5px;
  transition: all 0.3s ease;
}

.nav-hamburger {
  display: none;
}

@media only screen and (max-width: 1268px) {
  .logo a {
    font-size: 45px;
  }

  .nav-links li a {
    font-size: 15px;
  }
}

@media only screen and (max-width: 1024px) {
  .logo a {
    font-size: 35px;
  }

  .nav-links li a {
    font-size: 14px;
  }
}

@media only screen and (max-width: 940px) {
  .logo {
    margin: 0 35.5px;
  }

  .nav-links {
    margin: 0 35.5px;
    margin-left: 0;
  }

  .nav-links li a {
    font-size: 13px;
  }
}

@media only screen and (max-width: 850px) {
  .logo a {
    font-size: 28px;
  }
}

@media only screen and (max-width: 768px) {
  .logo {
    margin: 0 23.5px;
  }

  .nav-links {
    margin: 0 23.5px;
    margin-left: 0;
  }

  .logo a {
    font-size: 23px;
    font-weight: 600;
  }
}

@media only screen and (max-width: 640px) {
  .nav-wrapper {
    position: fixed;
    width: 100%;
    height: 50px;
    z-index: 3;
    top: 0;
    left: 0;
  }

  .logo {
    margin: 10px 20px;
    animation: none;
  }

  .logo a {
    font-size: 20px;
  }

  .nav-hamburger {
    display: block;
    position: absolute;
    cursor: pointer;
    right: 20px;
    top: 50%;
    transform: translate(-5%, -50%);
    z-index: 2;
    transition: all 0.7s ease;
  }

  .nav-links {
    position: fixed;
    margin: 10px 8px;
    margin-left: 0;
    margin-top: 0;
    top: 0;
    background: rgba(255, 255, 255, 0.97);
    height: 100vh;
    width: 100vw;
    flex-direction: column;
    justify-content: flex-start;
    padding-top: 100px;
    padding-left: 0px;
    clip-path: circle(50px at 90% -20%);
    -webkit-clip-path: circle(50px at 90% -20%);
    transition: all 0.6s ease-out;
    pointer-events: none;
  }

  .nav-links li a:hover {
    opacity: 0.5;
  }

  .nav-links.open {
    clip-path: circle(2000px at 90% -20%);
    -webkit-clip-path: circle(2000px at 90% -10%);
    pointer-events: all;
  }

  .nav-link-item {
    margin: 30px 10px;
  }

  .landing {
    flex-direction: column;
  }

  .nav-links li {
    opacity: 0;
  }

  .nav-links li a {
    font-size: 13px;
  }

  .nav-links li:nth-child(1) {
    transition: all 0.5s ease 0.1s;
  }

  .nav-links li:nth-child(2) {
    transition: all 0.5s ease 0.2s;
  }

  .nav-links li:nth-child(3) {
    transition: all 0.5s ease 0.3s;
  }

  .nav-links li:nth-child(4) {
    transition: all 0.5s ease 0.4s;
  }
  .nav-links li:nth-child(5) {
    transition: all 0.5s ease 0.45s;
  }

  .nav-link-item:last-child {
    margin-right: 10px;
  }

  li.fade {
    opacity: 1;
  }

  .toggle .line1 {
    transform: rotate(-45deg) translate(-5px, 6px);
  }

  .toggle .line2 {
    transition: all 0.7s ease;
    width: 0;
  }

  .toggle .line3 {
    transform: rotate(45deg) translate(-5px, -6px);
  }
}
