.page-title-wrapper {
  width: 100%;
}

.page-title-img {
  margin-top: 140px;
  background-color: var(--light-sage);

  background-attachment: fixed;
  background-position: center center;
  background-repeat: no-repeat;
  -moz-background-size: cover;
  -webkit-background-size: cover;
  -ms-background-size: cover;
  -o-background-size: cover;
  background-size: cover;
}

.top-page-spacer {
  padding-top: 350px;
}
.bottom-page-spacer {
  padding-top: 75px;
}

.page-title {
  letter-spacing: 0px;
  padding-top: 50px;
  padding-bottom: 80px;
  margin: auto;
  text-align: center;
  opacity: 0.55;
}

.page-title h1 {
  margin: 0px;
  padding: 0px;
}

@media only screen and (max-width: 1280px) {
  .page-title-img {
    background-attachment: scroll;
  }
}
@media only screen and (max-width: 640px) {
  .page-title-wrapper {
    margin-top: 50px;
  }

  .page-title-img {
    margin-top: 50px;
  }
}
