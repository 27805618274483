.couple-gallery-title-wrapper {
  margin-top: 140px;
  margin-bottom: 50px;
}

.couple-gallery-hero-img {
  height: 40vh;
  width: 100%;
  background-color: var(--light-sage);
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;

  display: flex;
  justify-content: center;
  align-items: center;
}

.couple-gallery-hero-title {
  margin: 0px;
  padding: 10px;
  color: white;
  background-color: rgb(0, 0, 0, 0.25);
}
