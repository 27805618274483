.services-wrapper {
  margin: 0px;
}

.services-content {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  margin: 0px 60px;
  gap: 100px;
}

.services-desc {
  max-width: 640px;
  line-height: 30px;
}

.service-pckg {
  list-style: none;
  display: flex;
  align-items: center;
  flex-direction: row;
  justify-content: center;
  height: 100%;
  width: 100%;
  padding-left: 0;
  gap: 70px;
  opacity: 0;
}

.service-pckg-appear {
  -moz-animation: on-scroll-load 1s forwards;
  -webkit-animation: on-scroll-load 1s forwards;
  -ms-animation: on-scroll-load 1s forwards;
  -o-animation: on-scroll-load 1s forwards;
  animation: on-scroll-load 1s forwards;
}

.service-pckg li {
  flex-basis: 500px;
  margin: 0px;
  position: relative;
}

.service-pckg .pckg-img img {
  object-fit: cover;
  max-width: 100%;
  height: auto;
  vertical-align: middle;
}

.pckg-img-numtext {
  position: absolute;
  left: 10px;
  top: -100px;
  font-size: 70px;
  color: rgb(229, 193, 137);
}

.pckg-desc {
  max-width: 75%;
}

.pckg-desc h2 {
  text-align: center;
  margin: 0;
  padding: 0;
  font-size: 40px;
}

.pckg-desc p {
  margin: 0;
  padding: 0;
  margin-top: 25px;
  line-height: 50px;
  font-size: 19px;
}

@media only screen and (max-width: 1200px) {
  .pckg-desc h2 {
    font-size: 30px;
  }

  .pckg-desc p {
    margin-top: 15px;
    line-height: 45px;
    font-size: 17px;
  }
}

@media only screen and (max-width: 1080px) {
  .pckg-desc h2 {
    font-size: 25px;
  }

  .pckg-desc p {
    margin-top: 5px;
    line-height: 40px;
    font-size: 17px;
  }
}

@media only screen and (max-width: 1000px) {
  .pckg-desc h2 {
    font-size: 20px;
  }

  .pckg-desc p {
    line-height: 35px;
    margin-top: 5px;
    font-size: 14px;
  }
}

@media only screen and (max-width: 860px) {
  .services-content {
    margin: 0px 80px;
  }
  .service-pckg {
    flex-direction: column;
    gap: 30px;
    width: 90%;
  }

  .service-pckg li {
    flex-basis: 100px;
  }

  .services-content ul:nth-child(1) {
    flex-direction: column-reverse;
  }
  .services-content ul:nth-child(3) {
    flex-direction: column-reverse;
  }
  .services-content ul:nth-child(5) {
    flex-direction: column-reverse;
  }

  .pckg-desc {
    max-width: 100%;
  }

  .pckg-desc h2 {
    font-size: 40px;
  }

  .pckg-desc p {
    font-size: 19.25px;
    margin-top: 30px;
  }

  .services-content {
    gap: 50px;
  }
}
@media only screen and (max-width: 640px) {
  .services-content {
    margin: 0px 10px;
  }
}
