.contact-wrapper {
  margin: 0 100px;
}

.contact-content {
  list-style: none;
  padding-left: 0;
  display: flex;
  justify-content: space-evenly;
  gap: 50px;
}

.contact-title-and-form-wrapper {
  width: 40%;
}

.contact-pic-wrapper {
  width: 40%;
  min-height: 500px;
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
}

.contact-content li:nth-child(1) {
  text-align: center;
}
.contact-content li p {
  font-size: 14px;
}

@media only screen and (max-width: 640px) {
  .contact-wrapper {
    margin: 0 20px;
  }
}
