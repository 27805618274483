.home-splash-cover {
  height: 100vh;
  width: 100vw;
  overflow: hidden;
  position: relative;
  background-color: var(--light-sage);
}

.home-splash-cover p {
  position: absolute;
  left: 25%;
  top: 25%;
  z-index: 9;
  font-size: 23px;
  opacity: 0;
  -webkit-animation: fadein 1s 0.25s forwards; /* Safari, Chrome and Opera > 12.1 */
  -moz-animation: fadein 1s 0.25s forwards; /* Firefox < 16 */
  -ms-animation: fadein 1s 0.25s forwards; /* Internet Explorer */
  -o-animation: fadein 1s 0.25s forwards; /* Opera < 12.1 */
  animation: fadein 1s 0.25s forwards;
}

.home-splash-cover img {
  position: absolute;
  left: 50%;
  top: 50%;
  z-index: 2;
  width: 1980px;
  height: 1080px;
  transform: translate(-50%, -50%);
}

.home-spacer {
  background: white;
  height: 15px;
  width: 100%;
}

.home-gallery-first {
  display: flex;
  flex-wrap: nowrap;
  margin: 100px;
  margin-bottom: 0px;
  padding-left: 0;
  gap: 50px;
  justify-content: space-evenly;
  align-items: center;
}

.home-gallery-first li {
  list-style: none;
  margin: 0;
  flex-basis: 4000px;
}

.home-gallery-first li img {
  object-fit: cover;
  max-width: 100%;
  height: auto;
  vertical-align: middle;
  opacity: 0;
}

.home-gallery-img-appear {
  -moz-animation: on-scroll-load 1s forwards;
  -webkit-animation: on-scroll-load 1s forwards;
  -ms-animation: on-scroll-load 1s forwards;
  -o-animation: on-scroll-load 1s forwards;
  animation: on-scroll-load 1s forwards;
}

.home-gallery-first li p {
  margin: 20px 5px;
  padding: 0;
  text-align: left;
  font-family: var(--site-font-1);
  font-size: 12px;
}

.home-services {
  margin: 125px 0;
  opacity: 0;
}

.home-services-appear {
  -moz-animation: on-scroll-load 1s forwards;
  -webkit-animation: on-scroll-load 1s forwards;
  -ms-animation: on-scroll-load 1s forwards;
  -o-animation: on-scroll-load 1s forwards;
  animation: on-scroll-load 1s forwards;
}

.home-services ul {
  display: flex;
  flex-direction: column;
  list-style: none;
  align-items: center;
  justify-content: space-evenly;
  padding-left: 0px;
  padding: 20px 0px;
  margin: 70px;
}

.home-services ul li {
  width: 50%;
  max-width: 750px;
}

.home-services ul li:nth-child(1) {
  padding-top: 3px;
}

.home-services ul li:nth-child(2) {
  text-align: center;
}

.home-services ul li p {
  text-align: left;
  padding: 0;
  margin: 0;
  font-size: 14px;
  line-height: 30px;
}

.home-services ul li:nth-child(1) p {
  font-size: 16px;
}

.home-services ul li:nth-child(2) p {
  text-align: left;
  padding: 0px 60px;
}

.home-services ul li:nth-child(2) a {
  display: inline-block;
  text-decoration: none;
  color: black;
  background-color: var(--medium-sage);
  font-size: 12px;
  transition: all ease-in-out 250ms;
  text-align: center;
  margin-top: 40px;
  margin-left: 10px;
  margin-right: 10px;
  padding: 10px 20px;
  max-width: 100px;
}

.home-services ul li:nth-child(2) a:hover {
  background-color: var(--light-sage);
  color: rgb(0, 0, 0, 0.8);
}

@media only screen and (max-width: 900px) {
  .home-services {
    margin: 100px 0px;
  }

  .home-services ul {
    flex-direction: column;
    align-items: center;
    gap: 50px;
  }

  .home-services ul li {
    width: 100%;
  }

  .home-services ul li:nth-child(2) p {
    text-align: left;
    padding: 0px 0px;
  }

  .home-gallery-first {
    margin: 50px;
    gap: 50px;
  }
}

@media only screen and (max-width: 640px) {
  .home-spacer {
    height: 40px;
  }

  .home-splash-cover img {
    position: absolute;
    left: 50%;
    top: 50%;
    z-index: 2;
  }

  .home-gallery-first {
    flex-direction: column;
    margin: 35px;
    padding-left: 0px;
    padding-right: 0px;
    gap: 20px;
  }

  .home-gallery-first li {
    flex-basis: 10px;
  }
}
