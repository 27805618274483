.gallery-item-wrapper {
  padding-left: 0px;
  list-style: none;
  display: flex;
  flex-direction: column;
  max-width: 100%;
}

.gal-item-img-cont {
  max-width: 500px;
}

.gal-item-img-cont img {
  max-width: 100%;
  height: auto;
  vertical-align: middle;
  object-fit: cover;
}

.gal-desc-text {
  margin: 5px;
}

.gal-desc-loc {
  margin: 5px;
  font-size: 12px;
}

.gal-item-img-cont button {
  padding: 0;
  margin: 0;
  border: none;
  cursor: pointer;
}
