.contact-form-wrapper {
  margin-top: 50px;
  width: 100%;
}

.contact-toast-success {
  background-color: #07bc0c !important;
  color: white !important;
}
.contact-toast-error {
  background-color: #e74c3c !important;
  color: white !important;
}

.contact-form-wrapper input,
.contact-form-wrapper label {
  display: block;
}

@media only screen and (max-width: 900px) {
  .contact-content {
    flex-direction: column;
    align-items: center;
  }

  .contact-content li {
    width: 100% !important;
  }
}

/*
.contact-form-wrapper label {
  font-size: 14px;
  margin-top: 40px;
}

.contact-form-wrapper input {
  width: 100%;
  border: none;
  margin-top: 5px;
  margin-bottom: 10px;
  border-bottom: 1px solid rgb(171, 197, 174);
  transition: all ease-in 0.2s;
}

.contact-form-wrapper input:focus {
  border-bottom: 1px solid rgb(171, 197, 174, 0.2);
  outline: none;
}

.contact-form-wrapper input:required {
  border-bottom: 1px solid red;
}


*/
