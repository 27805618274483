.footer-wrapper {
  display: flex;
  margin-top: 100px;
  padding: 50px 150px;
  background-color: var(--light-sage);
}

.footer-content {
  width: 100%;
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap;
}

.footer-item {
  width: 40%;
}

.footer-wrapper ul {
  display: flex;
  flex-direction: column;
  gap: 1px;
  list-style: none;
  padding-left: 0px;
}

.footer-text {
  margin: 0;
  padding: 0;
  font-size: 12px;
}

.footer-wrapper ul li a {
  opacity: 0.6;
  font-size: 12px;
  color: black;
}

.footer-wrapper ul li a:hover {
  opacity: 0.2;
  transition: all ease-in-out 250ms;
}

.footer-title {
  font-size: 13px;
  font-weight: 800;
  margin: 0;
}

.footer-insta-logo {
  margin: 10px 0;
  height: 25px;
  width: 25px;
  opacity: 0.9;
}

.footer-contact {
  display: flex;
  justify-content: flex-end;
  align-items: flex-end;
}

.footer-copyright {
  font-size: 10px;
  align-self: flex-end;
}

@media only screen and (max-width: 640px) {
  .footer-content {
    flex-direction: column;
    align-items: center;
    text-align: center;
  }

  .footer-copyright {
    align-self: center;
    width: 100%;
  }

  .footer-item {
    width: auto;
  }
}
